html {
  font-family: "Exo 2", sans-serif !important;
}

.w-dropdown-btn,
.w-dropdown-toggle {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  display: flex;
  flex-direction: row;
}

.drop-down-fix {
  padding-right: 0px;
}

.w-icon-dropdown-toggle {
  position: relative;
}

.icon.w-icon-dropdown-toggle.nav-link {
  margin-right: 0px !important;
}

.light {
  color: "white";
}

.thin {
  font-weight: 100;
}

.regularThin {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

a {
  text-decoration: none;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-direction: row;
  gap: 64px;
  list-style: none;
  padding: 0px;
}

.paginationPage,
.paginationPrevious,
.paginationNext {
  border-radius: 500px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #15171f;
  border-style: solid;
  border-width: 1px;
  border-color: #15171f;
  cursor: pointer;
}

.paginationPage:hover,
.paginationPrevious:hover,
.paginationNext:hover {
  background-color: #15171f;
  color: white;
}

.paginationActive {
  border-radius: 500px;
  width: 30px;
  height: 30px;
  background-color: #15171f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.paginationActive > a {
  color: white;
}

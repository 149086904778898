.Modal {
  z-index: 999;
  background-color: white;
  width: 50%;
}

.Overlay {
  z-index: 999;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.699);
  display: flex;

  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.nodeSelections {
  z-index: 999;
  background-color: white;
  width: 50%;
}

.nodeSelectionsOverlay {
  z-index: 999;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.699);
  display: flex;

  justify-content: space-around;
  align-items: center;
  align-content: center;
}

:root {
  --black: var(--backgrounds--dark);
  --backgrounds--dark: black;
  --light: #e6e6e6;
  --ash-black: #15171f;
  --barely-there-blue: #f4f8ff;
  --main-accent: #c8b8a6;
  --accent-gradient-dark: #8f7e6c;
  --aurora-base: #d3f4e4;
  --ash: #30333d;
  --light-hover: #e6e6e640;
  --surfaces--card-surface-dark: #272629;
  --surfaces--card-surface-light: #e0e2e8;
  --separator-primary-light-1: #aeb2c2;
  --spacing-36x: 144px;
  --surfaces--navbar-surface: #ffffff05;
  --spacing-5x: 20px;
  --spacing-4x: 16px;
  --spacing-6x: 24px;
  --spacing-16x: 64px;
  --text-colors--text-tertiary: #aeb2c2;
  --primary-dark: #1b1c1e;
  --spacing-2x: 8px;
  --border-styles--radius-full: 500px;
  --ash-hover: #30333d80;
  --spacing-12x: 48px;
  --border-styles--radius-small: 4px;
  --radius-m: 8px;
  --surfaces--card-surface-light-2: #e0e2e8;
  --surfaces--surface-footer-bg: #232428;
  --spacing-32x: 128px;
  --video-overlay: #15171f96;
  --badge-border-light: #aeb2c2;
  --badge-border-dark: #414248;
  --surfaces--badge-surface-dark: #aeb2c20d;
  --text-colors--badge-text-dark: #aeb2c2;
  --background-primary-light-2: #eaebef;
  --spacing-28x: 112px;
  --spacing-10x: 40px;
  --text-tertiary-light-2: #6c6f79;
  --text-colors--button-secondary-text-dark: white;
  --form-border-light: #c2c5d1;
  --form-text-secondary-light: #828591;
  --button-colors--button-secondary-border-light: #ccced8;
  --button-colors--button-secondary-surface-dark: #aeb2c20d;
  --text-colors--badge-text-dark-date: white;
  --text-colors--button-secondary-text-light: #1b1c1e;
  --button-colors--button-focus-light: #aeb2c2;
  --button-colors--button-primary-surface-light: #1b1c1e;
  --button-colors--button-primary-surface-dark: #f4f5f7;
  --text-colors--button-primary-text-dark: #1b1c1e;
  --button-colors--button-secondary-surface-light: #aeb2c20d;
  --surfaces--badge-surface-light: #ffffff26;
  --surfaces--general-surface-1: #b5f3d4;
  --general-purple: #9795b5;
  --general-brown: #c8b8a6;
  --plum-text-unselected: #312f4f;
  --text-tertiary-plum: #9795b5;
  --spacing-20x: 80px;
  --button-colors--button-secondary-border-dark: #414248;
  --spacing-3x: 12px;
  --light-aurora: #f0fcf6;
  --separator-secondary-dark: #414248;
  --backgrounds--dark-blue: #0a0d4b;
  --surfaces--form-primary-surface-dark: #f4f5f7;
  --backgrounds--background-primary-taupe: #ded4c9;
  --backgrounds--background-primary-2: #eaebef;
  --separator-primary-aurora: #6c917f;
  --backgrounds--background-primary-aurora: #24302a;
  --backgrounds--plum: #201f35;
  --card-surface-aurora: #2c3f35;
  --card-surface-taupe: #d3c6b7;
  --surfaces--form-surface-dark: #2b2c30;
  --backgrounds--background-primary-light: #eaebef;
  --spacing-24x: 112px;
  --navbar-primary-light: #eaebef;
  --form-border-dark: #575961;
  --turqouise: #3dff9e;
}

.w-layout-blockcontainer {
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.w-layout-layout {
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  grid-auto-columns: 1fr;
  justify-content: center;
  padding: 20px;
}

.w-layout-cell {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.w-embed-youtubevideo {
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg");
  background-position: 50%;
  background-size: cover;
  width: 100%;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

.w-layout-hflex {
  flex-direction: row;
  align-items: flex-start;
  display: flex;
}

.w-layout-vflex {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  height: 38px;
  min-height: 30px;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon,
.w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus,
.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus,
.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus,
.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem,
.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem,
.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commerceorderconfirmationcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

@media screen and (max-width: 991px) {
  .w-layout-blockcontainer {
    max-width: 728px;
  }
}

@media screen and (max-width: 767px) {
  .w-layout-blockcontainer {
    max-width: none;
  }

  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput,
  .w-commerce-commercecheckoutshippingfullname,
  .w-commerce-commercecheckoutshippingstreetaddress,
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity,
  .w-commerce-commercecheckoutshippingstateprovince,
  .w-commerce-commercecheckoutshippingzippostalcode,
  .w-commerce-commercecheckoutshippingcountryselector,
  .w-commerce-commercecheckoutcardnumber,
  .w-commerce-commercecheckoutcardexpirationdate,
  .w-commerce-commercecheckoutcardsecuritycode,
  .w-commerce-commercecheckoutbillingfullname,
  .w-commerce-commercecheckoutbillingstreetaddress,
  .w-commerce-commercecheckoutbillingstreetaddressoptional,
  .w-commerce-commercecheckoutbillingcity,
  .w-commerce-commercecheckoutbillingstateprovince,
  .w-commerce-commercecheckoutbillingzippostalcode,
  .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 38px;
  font-weight: bold;
  line-height: 44px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}

p {
  margin-bottom: 10px;
}

a {
  text-decoration: underline;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

blockquote {
  border-left: 5px solid #e2e2e2;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
}

.style-guide-annoucement {
  margin-bottom: 40px;
  font-family: Exo, sans-serif;
  font-size: 40px;
  font-weight: 300;
  line-height: 40px;
}

.style-guide-warning {
  color: red;
  background-color: #ffe7e8;
  padding: 20px;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.body {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #f3f3f3;
  flex-flow: column;
  font-family: Abcfavoritmono Book;
  display: block;
}

.text-block {
  color: #b8b8b8;
  border-top: 1px solid #b8b8b8;
  font-family: Exo, sans-serif;
}

.div-block {
  flex-flow: column;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
}

.link {
  color: #000;
  font-family: Exo, sans-serif;
  text-decoration: none;
}

.section {
  background-color: #fff;
  margin: 40px 10px;
}

.font-dark {
  background-color: var(--black);
  color: var(--backgrounds--dark);
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.div-block-3 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.font-light {
  background-color: var(--light);
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.text-block-2 {
  color: var(--backgrounds--dark);
}

.main-dark {
  background-color: var(--ash-black);
  color: var(--backgrounds--dark);
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.main-light {
  background-color: var(--barely-there-blue);
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.main-accent {
  background-color: var(--main-accent);
  color: var(--backgrounds--dark);
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.secondary-accent {
  background-color: var(--accent-gradient-dark);
  border-radius: 10px;
  width: 100%;
  height: 100px;
}

.accent-button {
  background-color: var(--aurora-base);
  color: var(--backgrounds--dark);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: none;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.accent-button:hover {
  background-color: #aae4c8;
}

.light-outline-button {
  border: 1px solid var(--light);
  color: var(--light);
  background-color: #0000;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 0;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.light-outline-button:hover {
  background-color: #e6e6e624;
}

.image {
  width: 100%;
}

.text-field {
  background-color: var(--ash);
  width: 100%;
}

.subscribe-form {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.subscribe-form-block {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.subscribe-field-label {
  color: var(--barely-there-blue);
  text-transform: capitalize;
  font-size: 32px;
  font-weight: 400;
  line-height: 44px;
}

.paragraph {
  color: var(--barely-there-blue);
}

.paragraph.subscription-text {
  color: var(--light-hover);
  font-size: 14px;
  line-height: 15px;
}

.event-card {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: var(--surfaces--card-surface-dark);
  color: var(--ash-black);
  border-radius: 10px;
  flex-flow: column;
  width: 450px;
  min-width: 450px;
  max-width: 700px;
  height: auto;
  min-height: auto;
  padding: 20px;
  font-family: Exo, sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
}

.event-card-tag-heading {
  color: var(--light);
  text-transform: uppercase;
  font-family: Exo, sans-serif;
  font-weight: 300;
}

.event-card-tag-container {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-5 {
  background-color: var(--light);
  border-radius: 500px;
  width: 10px;
  height: 10px;
}

.event-card-heading {
  z-index: 10;
  color: var(--surfaces--card-surface-light);
  text-align: left;
  text-transform: capitalize;
  margin-top: 0;
  font-family: Exo, sans-serif;
  font-weight: 400;
  position: relative;
}

.image-3 {
  z-index: 0;
  box-shadow: 0 0 200px 0 var(--separator-primary-light-1);
  vertical-align: middle;
  object-fit: cover;
  border-radius: 500px;
  justify-content: center;
  align-items: center;
  width: 330px;
  max-width: none;
  height: 330px;
  display: inline-flex;
  position: relative;
}

.event-card-location-container {
  color: var(--light);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.event-card-location,
.event-card-date {
  text-transform: uppercase;
  font-family: Exo, sans-serif;
  font-weight: 300;
}

.event-card-date-container {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-6 {
  background-color: var(--barely-there-blue);
  width: 10px;
  height: 10px;
}

.event-card-paragraph {
  color: var(--light);
  text-align: left;
  flex: 0 auto;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-weight: 300;
}

.paragraph-section-heading {
  border-bottom: 1px solid var(--light);
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 5px;
  font-weight: 400;
}

.paragraph-section {
  padding-top: 0;
  padding-bottom: 0;
}

.paragraph-section-paragraph {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.paragraph-section-right-pad {
  width: 100%;
}

.paragraph-section-paragraph-copy {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border-top: 1px solid var(--light);
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  padding-top: 48px;
  display: flex;
}

.partner-logo-bar {
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.partner-logo {
  aspect-ratio: auto;
  object-fit: contain;
  width: 150px;
  height: 50px;
}

.navbar-logo-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: 50px;
  display: flex;
}

.nav-bar {
  z-index: 99;
  padding: 0px var(--spacing-36x);
  background-color: var(--surfaces--navbar-surface);
  -webkit-backdrop-filter: blur(36px);
  backdrop-filter: blur(36px);
  font-family: Exo, sans-serif;
  position: absolute;
  inset: 0% 0% auto;
}

.div-block-8 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  border: 1px #000;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.nav-link {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: var(--light);
  letter-spacing: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.hero-section {
  background-image: none;
  background-position: 0 0;
  background-size: auto;
  background-attachment: scroll;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  min-height: 860px;
  font-family: Exo, sans-serif;
  font-weight: 400;
  display: flex;
  overflow: hidden;
}

.div-block-9 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.container {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: auto;
  display: flex;
  position: relative;
}

.container.vertical-nav {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 1170px;
  height: auto;
}

.container.pad-top-large {
  padding-top: 64px;
  position: relative;
}

.container.gapm {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: center;
}

.container.gap-m {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
}

.container.gap-l {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  max-width: none;
}

.container.left-justify {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1280px;
}

.container.left-justify.gap-m {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
}

.container.full-height {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.container.horizontal.pad-left-m {
  padding-left: var(--spacing-16x);
}

.container.spotlight-stack {
  flex-flow: row;
}

.icon {
  margin-right: 0;
  position: relative;
}

.text-block-3 {
  font-family: Exo, sans-serif;
  position: relative;
}

.hero-heading {
  width: 100%;
  font-size: 64px;
  font-weight: 400;
  line-height: 70px;
}

.hero-heading.light {
  margin-top: 0;
  margin-bottom: 0;
}

.hero-subheading {
  width: 70ch;
  font-weight: 300;
}

.hero-subheading.hide-mo {
  display: block;
}

.hero-button-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 380px;
  display: flex;
}

.event-card-section {
  background-color: var(--primary-dark);
  padding-top: 128px;
  padding-bottom: 128px;
  position: relative;
  overflow: clip;
}

.hero-overview-container {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}

.hero-overview-text {
  border-bottom: 1px solid var(--light);
  cursor: default;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.2s;
}

.hero-overview-text:hover {
  background-color: #e6e6e61c;
  padding: 10px;
}

.large-container {
  max-width: none;
  position: relative;
}

.slider-button-left {
  z-index: 99;
  border: 1px solid var(--light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--ash-hover);
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: all 0.2s;
  display: flex;
  position: absolute;
  inset: 105% 0 0% -2%;
}

.slider-button-left:hover {
  background-color: var(--light-hover);
}

.slider-button-left.hide-mobile {
  top: 50%;
  left: 2%;
}

.slider-button-icon {
  width: 50%;
  height: 50%;
}

.slider-button-right {
  z-index: 99;
  border: 1px solid var(--light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--ash-hover);
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: all 0.2s;
  display: flex;
  position: absolute;
  inset: 50% 2% 0% auto;
}

.slider-button-right:hover {
  background-color: var(--light-hover);
}

.body-2 {
  background-color: var(--ash-black);
  color: var(--light);
  font-family: Exo, sans-serif;
}

.paragraph-secction-button-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.paragraph-card-image {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 350px;
  position: relative;
}

.paragraph-card-image.large {
  width: 450px;
  height: 300px;
}

.div-block-10 {
  grid-column-gap: var(--spacing-4x);
  grid-row-gap: var(--spacing-4x);
  flex-flow: column;
  width: 100%;
  height: auto;
  display: flex;
}

.paragraph-card-heading {
  text-align: left;
  white-space: normal;
  overflow-wrap: normal;
  flex: 1;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
}

.paragraph-card-details {
  flex: 0 auto;
  font-family: Lato, sans-serif;
  font-size: 16px;
  line-height: 22px;
}

.durkin-section {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.durkin-section-div {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-image: radial-gradient(circle, #323b8b, #1a2480);
  border-radius: 10px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 33%;
  padding: 20px;
  display: flex;
}

.div-block-13 {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.image-4 {
  border-radius: 500px;
  width: 100%;
  height: auto;
}

.durkin-section-div-2 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  background-image: radial-gradient(circle, #323b8b, #1a2480);
  border-radius: 10px;
  flex-flow: column;
  justify-content: space-between;
  align-items: stretch;
  width: 66%;
  padding: 20px;
  display: flex;
}

.quick-stack-2 {
  padding-left: 0;
  padding-right: 0;
}

.text-block-4 {
  font-size: 20px;
  line-height: 24px;
}

.three-stack {
  grid-column-gap: var(--spacing-12x);
  grid-row-gap: var(--spacing-12x);
  padding: 0;
}

.three-stack.pad-top-m {
  padding-top: 36px;
}

.full-video {
  border-radius: 10px;
}

.full-video.pad-top-l {
  border-radius: var(--border-styles--radius-small);
  overflow: hidden;
}

.full-video.margin-top-l {
  margin-top: var(--spacing-12x);
}

.info-card {
  padding: var(--spacing-6x);
  border-radius: var(--radius-m);
  background-color: var(--surfaces--card-surface-light);
  flex-flow: column;
  flex: none;
  justify-content: space-between;
  width: 278px;
  min-width: 278px;
  max-width: 278px;
  height: 300px;
  font-family: Lato, sans-serif;
  display: flex;
}

.div-block-15 {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.info-card-icon {
  width: 20px;
  height: 20px;
}

.product-promo-card {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: var(--surfaces--card-surface-light-2);
  color: var(--ash-black);
  border-radius: 10px;
  flex-flow: column;
  width: auto;
  min-width: 350px;
  height: auto;
  padding: 20px;
  display: flex;
  overflow: hidden;
}

.image-5 {
  aspect-ratio: 1;
  object-fit: scale-down;
  background-color: #fff;
  border-radius: 500px;
  width: 100%;
  max-width: 330px;
  height: 100%;
  max-height: 330px;
  box-shadow: 0 0 200px #0003;
}

.div-block-17 {
  aspect-ratio: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.accordion-heading {
  text-transform: capitalize;
  font-weight: 400;
}

.accordion-selection {
  border-bottom: 1px solid var(--barely-there-blue);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 5px;
  display: flex;
}

.accordion-icon {
  width: 10px;
  height: 10px;
}

.accordion-text {
  text-transform: capitalize;
}

.div-block-18 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  width: 100%;
  display: flex;
}

.testimonial-card {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: var(--surfaces--card-surface-dark);
  color: var(--text-colors--text-tertiary);
  border-radius: 10px;
  flex-flow: column;
  flex: 1;
  width: 100%;
  height: auto;
  padding: 20px;
  font-family: Lato, sans-serif;
  display: flex;
}

.testimonial-card.light {
  background-color: var(--surfaces--card-surface-light-2);
  color: var(--black);
}

.testimonial-header-container {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.testimonial-image {
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

.testimonial-date {
  text-transform: uppercase;
  line-height: 14px;
}

.div-block-19 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.testimonial-name {
  color: var(--light);
}

.footer {
  background-color: var(--surfaces--surface-footer-bg);
  color: var(--surfaces--surface-footer-bg);
  height: auto;
  padding-top: 128px;
  padding-left: 144px;
  padding-right: 144px;
}

.quick-stack-4 {
  padding: 0;
}

.background-video {
  padding: var(--spacing-32x) var(--spacing-36x);
  background-color: var(--video-overlay);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 860px;
  display: flex;
  position: relative;
  inset: 0%;
}

.image-7 {
  width: 100px;
  max-width: 90%;
}

.breadcrumb-nav {
  width: 100%;
}

.masterclass-title {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}

.masterclass-hero-paragraph {
  text-align: center;
  width: 70ch;
  font-family: Exo, sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 24px;
}

.white-fill-button {
  background-color: var(--barely-there-blue);
  color: var(--backgrounds--dark);
  cursor: pointer;
  border-radius: 60px;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: none;
  height: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
}

.white-fill-button:hover {
  background-color: var(--badge-border-light);
}

.white-fill-button.margin-top-20 {
  flex: 0 auto;
  margin-top: 30px;
}

.masterclass-hero-details {
  width: 100%;
}

.tag {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid var(--ash);
  background-color: var(--ash-black);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding: 10px;
  display: flex;
}

.tag.rounded {
  background-color: var(--primary-dark);
  border-radius: 15px;
}

.tag.rounded.transparent {
  background-color: var(--light-hover);
  color: var(--ash-black);
  padding: 5px 15px;
}

.tag.rounded.transparent.selected {
  border-color: var(--black);
  color: var(--ash-black);
  border-radius: 15px;
  padding: 5px 10px;
}

.tag.rounded.transparent.unselected {
  border-color: var(--ash-hover);
  color: var(--video-overlay);
}

.tag.rounded.transparent.unselected:hover {
  border-color: var(--black);
  color: var(--black);
}

.tag.transparent {
  background-color: #15171f00;
}

.tag-icon {
  width: 15px;
  height: 15px;
}

.tag-text {
  text-transform: uppercase;
  padding: 5px;
}

.badge-dark {
  border: 0.5px solid var(--badge-border-dark);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--surfaces--badge-surface-dark);
  color: var(--text-colors--badge-text-dark);
  text-transform: uppercase;
  flex: none;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 16px;
}

.badge-dark.small {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 12px;
  line-height: 12px;
}

.masterclass-what-you-will-learn-section {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  border-top: 1px solid var(--ash);
  border-bottom: 1px solid var(--ash);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 100px;
  display: none;
}

.div-block-21 {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.light-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  background-color: var(--background-primary-light-2);
  color: var(--ash-black);
}

.light-background-section.small {
  padding-top: 24px;
  padding-bottom: 24px;
}

.light-background-section.white {
  background-color: #fff;
}

.left-adjusted-heading {
  text-align: left;
  text-transform: capitalize;
  width: 100%;
  margin: 10px 0;
  font-size: 44px;
  font-weight: 400;
  line-height: 46px;
}

.body-3 {
  font-family: Lato, sans-serif;
}

.vertical {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.vertical.gap20 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.vertical.gap-l {
  grid-column-gap: var(--spacing-32x);
  grid-row-gap: var(--spacing-32x);
}

.tag-bar {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.paragraph-7 {
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.agenda-table {
  width: 100%;
}

.agenda-line-item {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  border-bottom: 1px solid var(--ash);
  text-transform: uppercase;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

.youtube {
  border-radius: 20px;
  width: 100%;
  height: auto;
}

.div-block-22 {
  border-radius: 10px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.quick-stack-9 {
  width: 100%;
  padding: 40px 0;
}

.single-column-component {
  border-style: none none none solid;
  border-width: 1px;
  border-color: var(--ash);
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 20px;
  display: flex;
}

.white-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  color: var(--backgrounds--dark);
  background-color: #fff;
}

.white-background-section.full-height {
  height: auto;
  min-height: 100vh;
}

.div-block-25 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: wrap;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.staff-tag {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid var(--ash);
  background-color: var(--ash-black);
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding: 10px;
  display: flex;
}

.staff-tag.rounded {
  background-color: var(--barely-there-blue);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.staff-card-social-icon {
  background-color: var(--barely-there-blue);
  width: auto;
  height: auto;
  padding: 5px;
}

.staff-card-social-icon-container {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.staff-card {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
  background-color: var(--light);
  color: var(--ash-black);
  border-radius: 10px;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
  max-width: 400px;
  height: auto;
  min-height: auto;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.masterclass-location-details-container {
  border-radius: 10px;
  flex-flow: column;
  flex: 1;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: auto;
  padding: 0;
  display: flex;
  overflow: hidden;
}

.div-block-26 {
  background-color: var(--surfaces--card-surface-light);
  flex-flow: column;
  flex: 1;
  justify-content: space-between;
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
}

.div-block-27 {
  width: 100%;
}

.image-9 {
  width: 20px;
  height: 20px;
}

.dark-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  background-color: var(--primary-dark);
  color: var(--light);
}

.dark-background-section.masterclass-floating-boxes {
  padding-top: 48px;
  padding-bottom: 96px;
  position: static;
}

.dark-background-section.image {
  background-image: linear-gradient(#232428c7, #232428c7),
    url("../assets/images/pexels-anna-tarazevich-5629205-p-1600.jpg");
  background-position: 0 0, 0 0;
  background-size: auto, cover;
  justify-content: center;
  align-items: center;
  height: 60vh;
  display: flex;
}

.dark-background-section.full-height {
  height: 100vh;
}

.dark-background-section.full-height.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.dark-background-section.upcoming-events {
  padding-top: var(--spacing-6x);
  font-family: Exo, sans-serif;
}

.pricing-card-container {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 30px;
  display: flex;
}

.pricing-card {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: var(--surfaces--card-surface-light);
  border-radius: 10px;
  flex-flow: column;
  flex: 1;
  align-self: stretch;
  width: auto;
  min-width: 30%;
  height: auto;
  min-height: auto;
  max-height: none;
  padding: 20px;
  display: flex;
  position: relative;
}

.pricing-card.dark {
  background-color: var(--surfaces--card-surface-dark);
}

.pricing-card-price {
  color: var(--text-colors--text-tertiary);
  font-size: 96px;
  line-height: 100px;
}

.pricing-card-text {
  font-size: 24px;
  line-height: 26px;
}

.badge {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border: 1px solid var(--badge-border-dark);
  background-color: var(--surfaces--badge-surface-dark);
  border-radius: 5px;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 170px;
  padding: 8px;
  display: flex;
}

.pricing-card-top {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-style: none none solid;
  border-width: 1px 1px 0.5px;
  border-color: black black var(--ash-black);
  flex-flow: column;
  padding-bottom: 40px;
  display: flex;
}

.pricing-card-bottom {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: column;
  flex: 1;
  display: flex;
}

.pricing-card-bottom-bullet-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.pricing-card-bullet-icon {
  width: 20px;
  height: 20px;
}

.quick-stack-10 {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex: 1;
  width: 100%;
  padding: 0;
}

.checkout-field {
  margin-top: var(--spacing-2x);
  margin-bottom: var(--spacing-2x);
  padding: var(--spacing-4x);
  border-style: none none solid;
  border-width: 1px;
  border-color: black black var(--badge-border-dark);
  background-color: var(--surfaces--card-surface-dark);
  color: var(--text-colors--button-secondary-text-dark);
}

.checkout-field:focus {
  border-bottom-color: #fff;
}

.checkout-field.light {
  padding: var(--spacing-4x);
  border-bottom-color: var(--form-border-light);
  background-color: var(--surfaces--card-surface-light);
  color: var(--text-tertiary-light-2);
  height: 40px;
  font-size: 16px;
  line-height: 22px;
}

.checkout-field.light.dropdown {
  color: var(--text-tertiary-light-2);
  padding-top: 0;
  padding-bottom: 0;
}

.field-label-2 {
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.field-label-2.light {
  color: var(--form-text-secondary-light);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 17px;
}

.form-block {
  width: 100%;
}

.form {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
  flex-flow: column;
  width: 100%;
  display: flex;
}

.testimonial-container {
  width: 100%;
  padding: 0;
}

.testimonial-card-image {
  object-fit: cover;
  border-radius: 10px;
  width: 72px;
  height: 72px;
}

.div-block-31 {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.div-block-32 {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.paragraph-11 {
  flex: 1;
}

.image-10 {
  width: 200px;
}

.div-block-33 {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  font-family: Exo, sans-serif;
  display: flex;
}

.button-secondary-dark {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-secondary-surface-dark);
  color: var(--text-colors--button-secondary-text-dark);
  padding: 9.5px 24px 10.5px;
  font-family: Exo, sans-serif;
  font-size: 16px;
}

.button-secondary-dark:focus {
  border-width: 2px;
}

.light-nav-bar {
  z-index: 999;
  background-color: var(--text-colors--badge-text-dark-date);
  -webkit-backdrop-filter: blur(36px);
  backdrop-filter: blur(36px);
  color: var(--ash-black);
  padding: 0 60px;
  font-family: Lato, sans-serif;
  position: absolute;
  inset: 0% 0% auto;
}

.light-nav-link {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  color: var(--backgrounds--dark);
  letter-spacing: 0;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  display: flex;
}

.light-nav-link:hover {
  font-weight: 500;
}

.medium-button-secondary-light {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-secondary-surface-dark);
  color: var(--text-colors--button-secondary-text-light);
  letter-spacing: 0;
  padding: 9.5px 24px 10.5px;
  font-family: Exo, sans-serif;
  font-size: 16px;
  transition: all 0.2s;
}

.medium-button-secondary-light:hover {
  background-color: var(--main-accent);
}

.medium-button-secondary-light:focus {
  border-width: 2px;
  border-color: var(--button-colors--button-focus-light);
}

.navbar-container {
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.navbar-container.hide-mobile {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.button-primary-dark {
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-primary-surface-light);
  color: var(--text-colors--button-secondary-text-dark);
  padding: 13.5px 28px 14.5px;
  font-size: 20px;
}

.button-primary-dark:focus {
  border-width: 2px;
  border-color: var(--button-colors--button-focus-light);
}

.div-block-34 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.div-block-35 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: var(--backgrounds--dark);
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.button-primary-light {
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-primary-surface-dark);
  color: var(--text-colors--button-primary-text-dark);
  padding: 13.5px 28px 14.5px;
  font-size: 20px;
}

.button-primary-light:focus {
  border-width: 2px;
  border-color: var(--button-colors--button-focus-light);
}

.button-primary-light.full-width {
  text-align: center;
}

.div-block-36 {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.large-button-secondary-dark {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-secondary-surface-light);
  color: var(--text-colors--button-secondary-text-dark);
  padding: 13.5px 28px 14.5px;
  font-size: 20px;
}

.large-button-secondary-dark:focus {
  border-width: 2px;
  border-color: var(--button-colors--button-focus-light);
}

.large-button-secondary-light {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-secondary-surface-light);
  color: var(--text-colors--button-primary-text-dark);
  padding: 13.5px 28px 14.5px;
  font-size: 20px;
}

.large-button-secondary-light:focus {
  border-width: 2px;
}

.button-dense-primary-dark {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-primary-surface-light);
  color: var(--text-colors--button-secondary-text-dark);
  text-align: center;
  padding: 9.5px 24px 10.5px;
  font-family: Exo, sans-serif;
  font-size: 20px;
  transition: all 0.2s;
}

.button-dense-primary-dark:hover {
  background-color: var(--badge-border-dark);
}

.button-dense-primary-dark:focus {
  border-width: 2px;
}

.button-dense-primary-dark.hide-mobile {
  display: block;
}

.button-dense-primary-light {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-primary-surface-dark);
  color: var(--text-colors--button-primary-text-dark);
  text-align: center;
  padding: 9.5px 24px 10.5px;
  font-family: Exo, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.button-dense-primary-light:focus {
  border-width: 2px;
}

.button-dense-primary-light.margin-top-l {
  margin-top: var(--spacing-16x);
}

.icon-button-icon {
  object-fit: contain;
  width: 18px;
  height: 18px;
}

.icon-block,
.icon-block-dark {
  border: 1px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-secondary-surface-dark);
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
}

.badge-light {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
  border: 0.5px solid var(--badge-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--surfaces--badge-surface-light);
  color: var(--text-colors--button-secondary-text-light);
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 16px;
  display: flex;
}

.badge-light.small {
  padding: 8px 10px;
  font-size: 12px;
  line-height: 12px;
}

.date-badge-light {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 0.5px solid var(--badge-border-light);
  border-radius: var(--border-styles--radius-small);
  background-color: var(--surfaces--badge-surface-light);
  color: var(--text-colors--button-secondary-text-light);
  text-transform: uppercase;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.date-badge-light.general-1 {
  background-color: var(--surfaces--general-surface-1);
}

.date-badge-light.general-2 {
  background-color: var(--general-purple);
}

.date-badge-light.general-3 {
  background-color: var(--button-colors--button-secondary-border-light);
}

.date-badge-light.general-brown {
  background-color: var(--general-brown);
}

.date-badge-icon {
  width: 12px;
  height: 12px;
}

.date-badge-dark {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  border: 1px solid var(--badge-border-dark);
  border-radius: var(--border-styles--radius-small);
  color: var(--text-colors--badge-text-dark-date);
  text-transform: uppercase;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
}

.display-xxl {
  letter-spacing: -3px;
  font-family: Exo, sans-serif;
  font-size: 144px;
  font-weight: 400;
  line-height: 136px;
}

.display-xxl.unselected {
  color: var(--plum-text-unselected);
}

.display-xxl.selected {
  color: var(--text-tertiary-plum);
  text-align: justify;
}

.header {
  letter-spacing: -2px;
  font-family: Exo, sans-serif;
  font-size: 96px;
  line-height: 96px;
}

.header-xxl {
  letter-spacing: -2px;
  font-family: Exo, sans-serif;
  font-size: 56px;
  line-height: 60px;
}

.header-xxl.center {
  text-align: center;
  width: 100%;
}

.header-xxl.light {
  color: var(--text-tertiary-light-2);
  line-height: 64px;
}

.header-xxl.light.pad-top-m.center {
  width: 100%;
}

.header-xxl.pad-top-m {
  padding-top: var(--spacing-12x);
  line-height: 64px;
}

.header-xxl.plum {
  color: var(--text-tertiary-plum);
}

.header-xl {
  text-align: left;
  letter-spacing: -1px;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 52px;
}

.header-xl.light {
  color: var(--text-tertiary-light-2);
  text-align: left;
  font-size: 40px;
}

.header-xl.white {
  color: var(--barely-there-blue);
}

.header-xl.pad-top-l {
  padding-top: var(--spacing-6x);
}

.header-xl.top-pad-m {
  padding-top: 24px;
}

.header-xl.center {
  text-align: center;
}

.header-l {
  letter-spacing: -1px;
  flex: none;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 40px;
  line-height: 46px;
}

.header-l.light {
  color: var(--text-tertiary-light-2);
  margin-top: 0;
}

.header-l.thin {
  font-weight: 300;
}

.header-l.thin.light {
  color: var(--text-colors--text-tertiary);
  font-size: 36px;
}

.header-l.thin.pad-top-m {
  padding-top: 36px;
  font-size: 36px;
}

.header-l.pad-top-m {
  padding-top: var(--spacing-12x);
}

.header-l.center {
  text-align: center;
  width: 100%;
  font-weight: 400;
}

.header-m {
  letter-spacing: -1px;
  flex: 1;
  font-family: Exo, sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 34px;
}

.header-m.full-width {
  width: 100%;
}

.header-m.thin {
  font-weight: 300;
}

.header-s {
  color: var(--black);
  letter-spacing: 0;
  text-transform: none;
  flex: 0 auto;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 24px;
  line-height: 26px;
}

.header-s.center {
  text-align: center;
  flex: none;
}

.header-s.regular {
  font-weight: 400;
}

.header-s.regular.pad-top-xl {
  padding-top: var(--spacing-32x);
}

.header-s.pad-top-m {
  padding-top: var(--spacing-16x);
}

.header-xs {
  color: var(--button-colors--button-secondary-border-dark);
  flex: 0 auto;
  width: 100%;
  margin: 0;
  font-family: Exo, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}

.header-xs.white {
  color: var(--barely-there-blue);
}

.header-xs.light {
  color: var(--light);
  margin-top: 0;
  margin-bottom: 0;
}

.header-xs.light.underline {
  border-bottom: 1px solid var(--text-colors--text-tertiary);
  border-radius: 0.5px;
}

.header-xs.light.center {
  text-align: center;
}

.header-xs.light.center.underline {
  padding-bottom: var(--spacing-2x);
  border-style: none none solid;
  border-width: 1px;
  border-color: black black var(--form-text-secondary-light);
}

.header-xs.title-underline {
  padding-bottom: var(--spacing-6x);
  border-bottom: 1px solid var(--text-colors--badge-text-dark);
  border-radius: 1px;
}

.header-xs.title-underline.light {
  color: var(--light);
}

.header-xs.center {
  text-align: center;
}

.header-xs.product-summary {
  padding-bottom: var(--spacing-6x);
  border-bottom: 1px solid var(--form-text-secondary-light);
}

.div-block-37 {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.body-l {
  text-align: left;
  max-width: 920px;
  font-family: Exo, sans-serif;
  font-size: 22px;
  line-height: 30px;
  display: block;
  position: relative;
}

.body-l.bold {
  font-family: Exo, sans-serif;
}

.body-l.white {
  color: var(--light);
}

.body-l.pad-top-m {
  padding-top: 24px;
}

.body-l.pad-top-m.thin {
  font-weight: 300;
}

.body-l.pad-top-m.center {
  text-align: center;
  width: 100%;
}

.body-l.thin {
  font-weight: 300;
}

.body-l.center {
  text-align: center;
}

.body-m {
  text-transform: none;
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 18px;
  line-height: 22px;
}

.body-m.bold {
  font-family: Exo, sans-serif;
}

.body-m.top-pad-s {
  padding-top: 12px;
  font-weight: 300;
}

.body-m.pad-top-m {
  padding-top: 24px;
}

.body-m.light {
  color: var(--text-tertiary-light-2);
}

.body-m.light.short {
  width: 550px;
}

.body-s {
  color: var(--button-colors--button-secondary-border-dark);
  margin-bottom: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.body-s.bold {
  font-family: Exo, sans-serif;
  font-weight: 600;
}

.body-s.light {
  color: var(--text-colors--text-tertiary);
}

.body-s.light.pad-top-s {
  padding-top: 12px;
}

.body-s.light.extra-thin {
  font-weight: 100;
}

.body-s.light.short {
  width: 500px;
}

.body-s.extra-thin {
  font-weight: 200;
}

.body-s.overlay {
  z-index: 99;
  padding: var(--spacing-2x);
  margin-bottom: 0;
  position: absolute;
  inset: auto 0% 0%;
}

.caption-s,
.caption-s.bold {
  font-family: Exo, sans-serif;
  line-height: 16px;
}

.caption-s.light {
  color: var(--text-tertiary-plum);
}

.caption-xs {
  font-family: Exo, sans-serif;
  font-size: 13px;
  line-height: 16px;
}

.eyebrow {
  text-transform: uppercase;
  font-family: Abcfavoritmono;
}

.eyebrow.l {
  letter-spacing: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.eyebrow.s {
  color: var(--button-colors--button-secondary-border-dark);
  font-family: Exo, sans-serif;
  font-size: 12px;
  line-height: 14px;
}

.eyebrow.s.light {
  color: var(--text-colors--text-tertiary);
}

.eyebrow.s.light-aurora {
  color: var(--light-aurora);
}

.navigation {
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.button {
  color: var(--light);
  text-decoration: none;
}

.button.l {
  font-family: Exo, sans-serif;
  font-size: 20px;
  line-height: 20px;
}

.button.m {
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.button.m.center {
  text-align: center;
}

.button.m.dark {
  color: var(--black);
  text-decoration: none;
}

.button.m.dark.center.margin-top-m {
  margin-top: var(--spacing-6x);
}

.button.s {
  font-family: Exo, sans-serif;
  line-height: 14px;
}

.button.s.footer-link {
  color: var(--light);
}

.input-text {
  font-family: Exo, sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.footer-stack {
  width: 100%;
  padding: 24px 0;
}

.footer-link-stack {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  padding: 0;
}

.cell-3 {
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
}

.cell-4,
.cell-5,
.cell-6 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  color: var(--text-colors--text-tertiary);
}

.footer-social-bar {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.footer-social-icon {
  width: 100%;
  height: 100%;
}

.footer-social-link {
  width: 18px;
  height: 18px;
}

.footer-logo {
  opacity: 0.23;
  padding-top: 24px;
  padding-bottom: 24px;
}

.footer-privacy-bar {
  border-top: 1px solid var(--separator-secondary-dark);
  color: var(--text-colors--text-tertiary);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.privacy-links {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.light-section {
  padding: 128px 144px;
}

.vertical-container {
  max-width: none;
}

.subpage-header {
  border-top: 1px solid var(--button-colors--button-secondary-border-light);
  padding-top: 48px;
}

.badge-text-centered {
  text-align: center;
  font-family: Exo, sans-serif;
}

.background-video-masterclass {
  background-color: #0a0d4b87;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
  display: flex;
  position: relative;
  inset: 0%;
}

.display-xl {
  letter-spacing: -3px;
  font-family: Exo, sans-serif;
  font-size: 90px;
  font-weight: 400;
  line-height: 136px;
}

.display-xl.blue {
  color: var(--text-tertiary-light-2);
}

.div-block-38 {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.horizontal {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.blue-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  background-color: var(--backgrounds--dark-blue);
  color: var(--light);
  position: relative;
}

.masterclass-nav-logo {
  object-fit: contain;
  width: 200px;
}

.div-block-39 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.button-dense-primary-dark-copy {
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--general-brown);
  color: var(--ash-black);
  padding: 9.5px 24px 10.5px;
  font-size: 20px;
}

.button-dense-primary-dark-copy:focus {
  border-width: 2px;
}

.floating-box {
  border-radius: var(--border-styles--radius-small);
  background-color: var(--button-colors--button-focus-light);
  color: var(--surfaces--surface-footer-bg);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  display: flex;
  box-shadow: 0 2px 5px #0003;
}

.masterclass-floating-boxes {
  z-index: 99;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex: 1;
  width: 100%;
  padding: 0 0 5px;
  position: absolute;
  inset: 72px 0% auto;
}

.div-block-40 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.image-11 {
  flex: none;
  width: 30%;
}

.div-block-41 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  display: flex;
}

.button-group {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  max-width: none;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
}

.button-group.no-pad {
  padding-top: 0;
  padding-bottom: 0;
}

.cell-7,
.cell-8 {
  justify-content: flex-end;
  align-items: center;
}

.full-width-stack {
  grid-column-gap: var(--spacing-20x);
  grid-row-gap: var(--spacing-20x);
  width: 100%;
  padding: 0;
}

.full-width-stack.pad-top-xl {
  padding-top: var(--spacing-12x);
}

.full-width-stack.pad-top-m {
  padding-top: var(--spacing-6x);
}

.full-width-stack.pad-top-l {
  padding-top: var(--spacing-16x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
}

.image-info-card {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
}

.image-info-card-icon {
  object-fit: cover;
  width: 30px;
  height: 30px;
}

.image-info-card-header-container {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.quad-stack {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100%;
  padding: 0;
}

.glp-card {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-radius: var(--border-styles--radius-small);
  background-color: var(--surfaces--form-primary-surface-dark);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 24px;
  display: flex;
}

.glp-image {
  vertical-align: middle;
  object-fit: contain;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 250px;
  display: inline-flex;
}

.glp-info-container {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.double-stack {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100%;
  padding: 0;
}

.brown-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  background-color: var(--backgrounds--background-primary-taupe);
}

.faq-row {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: block;
}

.faw-container {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 36px;
  padding-bottom: 36px;
  display: flex;
}

.div-block-42 {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.lightgrey-background-section {
  background-color: var(--backgrounds--background-primary-2);
  color: var(--ash-black);
  padding: 128px 144px;
  font-family: Exo, sans-serif;
}

.featured-item {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-left: 1px solid #000;
  padding-left: 16px;
}

.div-block-43 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.full-width.gap-s {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.full-width.space-between {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.link-block {
  color: var(--backgrounds--dark);
  text-decoration: none;
}

.link-block.icon-button {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.button-m {
  color: var(--backgrounds--dark);
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
}

.tiny-icon {
  width: 10px;
  height: 10px;
}

.tag-s {
  border-radius: var(--border-styles--radius-full);
  color: var(--ash-black);
  text-transform: uppercase;
  border: 0.5px solid #000;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 230px;
  padding: 8px 10px;
  text-decoration: none;
  display: flex;
}

.hide-desktop {
  display: none;
}

.green-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  border-style: solid none none;
  border-width: 1px;
  border-color: var(--separator-primary-aurora);
  background-color: var(--backgrounds--background-primary-aurora);
  color: var(--light);
}

.stat-item {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  border-style: none none none solid;
  border-width: 1px;
  border-left-color: var(--separator-primary-aurora);
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16px;
}

.large-photo-left {
  border-radius: var(--radius-m);
  object-fit: cover;
  width: 100%;
  height: 700px;
}

.large-photo-right {
  border-radius: var(--radius-m);
  object-fit: cover;
  width: 100%;
  height: 338px;
}

.cell-12 {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.plum-background-section {
  padding: var(--spacing-32x) var(--spacing-36x);
  background-color: var(--backgrounds--plum);
  color: var(--light);
  position: relative;
  overflow: clip;
}

.full-width-container {
  padding-top: var(--spacing-12x);
  grid-column-gap: var(--spacing-12x);
  grid-row-gap: var(--spacing-12x);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.timeline-line {
  background-color: var(--plum-text-unselected);
  width: 100%;
  height: 1px;
  position: absolute;
}

.timeline-point {
  border-radius: var(--border-styles--radius-full);
  background-color: var(--text-tertiary-plum);
  width: 16px;
  height: 16px;
  position: absolute;
  top: 57px;
  left: 240px;
}

.team-card {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  background-color: var(--light);
  color: var(--ash-black);
  border-radius: 10px;
  flex-flow: column;
  flex: none;
  justify-content: flex-start;
  align-items: flex-start;
  width: 408px;
  height: auto;
  min-height: auto;
  padding: 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.div-block-47 {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.card-type-4 {
  padding: var(--spacing-5x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-radius: var(--border-styles--radius-small);
  background-color: var(--surfaces--card-surface-light);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.card-type-4.margin-top-l {
  margin-top: var(--spacing-16x);
}

.card-type-4-image {
  width: 72px;
  height: 72px;
}

.div-block-48 {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.parner-card-container {
  padding-top: var(--spacing-6x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.partner-card {
  padding: var(--spacing-6x) var(--spacing-5x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-left: 1px solid var(--separator-primary-light-1);
  flex-flow: column;
  width: 290px;
  height: 300px;
  display: flex;
}

.partner-card-image {
  border-radius: var(--spacing-2x);
  width: 72px;
  height: 72px;
}

.partner-products-container {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.partner-products-container.pad-top-m {
  padding-top: var(--spacing-6x);
  flex-flow: wrap;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
}

.partner-product {
  background-color: var(--surfaces--card-surface-light);
  flex: none;
  width: 330px;
  height: auto;
  min-height: 430px;
}

.partner-product-image {
  border-top-left-radius: var(--radius-m);
  border-top-right-radius: var(--radius-m);
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.partner-product-title {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.div-block-49 {
  padding: var(--spacing-5x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-bottom-left-radius: var(--radius-m);
  border-bottom-right-radius: var(--radius-m);
  background-color: var(--surfaces--card-surface-light);
  flex-flow: column;
  width: 100%;
  height: auto;
  line-height: 14px;
  display: flex;
}

.light-form {
  width: 100%;
  height: 100%;
}

.light-form.pad-top-l {
  padding-top: var(--spacing-16x);
}

.form-half-width {
  padding-top: var(--spacing-2x);
  padding-bottom: var(--spacing-2x);
  width: 100%;
}

.form-2-fields {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.featured-event {
  padding-top: var(--spacing-16x);
  width: 100%;
}

.featured-event-image {
  border-top-left-radius: var(--spacing-2x);
  border-top-right-radius: var(--spacing-2x);
  object-fit: cover;
  width: 100%;
  height: 464px;
}

.featured-event-details-bar {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.featured-event-title-bar {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.featured-event-title {
  grid-column-gap: var(--spacing-5x);
  grid-row-gap: var(--spacing-5x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.featured-event-details {
  padding: var(--spacing-5x) var(--spacing-6x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-bottom-left-radius: var(--spacing-2x);
  border-bottom-right-radius: var(--spacing-2x);
  background-color: var(--card-surface-aurora);
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.featured-event-details.brown {
  background-color: var(--card-surface-taupe);
}

.featured-event-details.plum {
  background-color: var(--plum-text-unselected);
}

.div-block-50 {
  grid-column-gap: var(--spacing-5x);
  grid-row-gap: var(--spacing-5x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.event-filter-bar {
  padding-top: var(--spacing-16x);
  padding-bottom: var(--spacing-5x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.badge-text {
  text-align: center;
}

.event-card-type-2 {
  padding: var(--spacing-5x) var(--spacing-6x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-radius: var(--radius-m);
  background-color: var(--surfaces--card-surface-dark);
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.event-card-type-2.light {
  background-color: var(--surfaces--card-surface-light);
}

.events-list {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: column;
  width: 100%;
  display: flex;
}

.events-list.pad-topm {
  padding-top: var(--spacing-16x);
}

.class-list {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: column;
  width: 100%;
  display: flex;
}

.class-list.pad-top-m {
  padding-top: var(--spacing-6x);
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
}

.event-card-type-3 {
  padding: var(--spacing-5x) var(--spacing-6x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-style: none none solid;
  border-width: 1px;
  border-color: black black var(--separator-secondary-dark);
  border-radius: 0;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.class-row {
  grid-column-gap: var(--spacing-5x);
  grid-row-gap: var(--spacing-5x);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.link-block-2 {
  color: var(--black);
  flex: none;
  text-decoration: none;
}

.staff-card-container {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  display: flex;
}

.div-block-51 {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.shop-filter-container {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.shop-filter-container.pad-top-m {
  padding-top: var(--spacing-6x);
}

.shop-filter {
  flex: 1;
}

.shop-chosen-filters-container {
  grid-column-gap: var(--radius-m);
  grid-row-gap: var(--radius-m);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.link-block-3 {
  justify-content: flex-start;
  align-items: center;
  width: 8px;
  height: 8px;
  display: flex;
}

.product-container {
  flex-flow: wrap;
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

.product-card {
  flex: none;
  width: 25%;
  height: auto;
  padding: 20px;
}

.div-block-52 {
  position: relative;
}

.link-block-4 {
  text-decoration: none;
}

.dropdown-link {
  padding-top: var(--spacing-3x);
  padding-bottom: var(--spacing-3x);
  background-color: var(--button-colors--button-primary-surface-light);
  color: var(--text-colors--badge-text-dark-date);
  font-size: 16px;
  line-height: 16px;
}

.dropdown-link:hover {
  background-color: var(--surfaces--form-surface-dark);
}

.dropdown-list {
  border-radius: var(--spacing-32x);
}

.dropdown-list.w--open {
  border: 1px solid var(--badge-border-dark);
  border-radius: var(--border-styles--radius-small);
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.field-group {
  padding-top: var(--spacing-2x);
  padding-bottom: var(--spacing-2x);
  flex: 1;
}

.signup-section {
  width: 100vw;
  height: 100vh;
}

.signup-left-side {
  justify-content: space-between;
  align-items: center;
}

.signup-stack {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 100%;
  padding: 0;
}

.signup-image {
  aspect-ratio: auto;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.signup-nav-bar {
  padding: var(--spacing-3x) var(--spacing-36x);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  display: flex;
}

.signup-logo {
  width: 208px;
}

.signup-footer {
  padding: var(--spacing-3x) var(--spacing-36x);
  width: 100%;
  height: 36px;
}

.center {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signup-form {
  grid-column-gap: var(--spacing-10x);
  grid-row-gap: var(--spacing-10x);
  flex-flow: column;
  width: 448px;
  display: flex;
}

.signup-login-link {
  grid-column-gap: var(--radius-m);
  grid-row-gap: var(--radius-m);
  justify-content: center;
  align-items: center;
  display: flex;
}

.onbording-section {
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

.signup-progress-group {
  width: 100%;
}

.signup-progress-bar-text-group {
  grid-column-gap: var(--radius-m);
  grid-row-gap: var(--radius-m);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.code-embed-3 {
  color: var(--black);
}

.div-block-53 {
  z-index: 9999;
  padding: var(--spacing-10x) var(--spacing-6x);
  grid-column-gap: var(--spacing-10x);
  grid-row-gap: var(--spacing-10x);
  border: 1px solid var(--button-colors--button-secondary-border-dark);
  border-radius: var(--radius-m);
  background-color: var(--surfaces--card-surface-dark);
  flex-flow: column;
  width: 624px;
  height: auto;
  display: flex;
  position: sticky;
  inset: 75% 0% auto 5%;
}

.div-block-54 {
  grid-column-gap: var(--spacing-4x);
  grid-row-gap: var(--spacing-4x);
  flex-flow: column;
  display: flex;
}

.cookies-close-button {
  padding: var(--spacing-4x);
  border: 1px solid var(--badge-border-dark);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--surfaces--card-surface-dark);
  position: absolute;
  inset: 24px 24px auto auto;
}

.image-13 {
  width: 18px;
  height: 18px;
}

.button-primary-blue {
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-primary-surface-light);
  color: var(--text-colors--button-secondary-text-dark);
  padding: 13.5px 28px 14.5px;
  font-size: 20px;
}

.button-primary-blue:focus {
  border-width: 2px;
  border-color: var(--button-colors--button-focus-light);
}

.slider-container {
  box-sizing: content-box;
  grid-column-gap: var(--spacing-5x);
  grid-row-gap: var(--spacing-5x);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  overflow: scroll;
}

.pad-top-m {
  padding-top: var(--spacing-16x);
}

.account-nav-container {
  grid-column-gap: var(--spacing-3x);
  grid-row-gap: var(--spacing-3x);
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.account-nav-header {
  grid-column-gap: var(--spacing-3x);
  grid-row-gap: var(--spacing-3x);
  justify-content: flex-start;
  align-items: center;
  width: 330px;
  display: flex;
}

.user-image {
  border-radius: var(--border-styles--radius-full);
  width: 72px;
  height: 72px;
}

.account-right-side {
  grid-column-gap: var(--spacing-20x);
  grid-row-gap: var(--spacing-20x);
  flex-flow: column;
  flex: 1;
  display: flex;
}

.account-stack {
  grid-column-gap: var(--spacing-20x);
  grid-row-gap: var(--spacing-20x);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.certificates-container {
  flex-flow: column;
  display: flex;
}

.certificates-group {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: wrap;
  display: flex;
}

.certificate {
  padding: var(--spacing-6x) var(--spacing-5x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-radius: var(--radius-m);
  background-color: var(--surfaces--card-surface-light);
  flex-flow: column;
  width: 410px;
  height: 600px;
  display: flex;
}

.certificate-image {
  border-radius: var(--border-styles--radius-full);
  width: 272px;
  height: 272px;
}

.cert-completion-group,
.cert-signature-group {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.image-14 {
  height: 36px;
}

.account-nav-selections-container {
  flex-flow: column;
  display: flex;
}

.account-nav-selection {
  padding-right: var(--spacing-3x);
  padding-left: var(--spacing-3x);
  color: var(--backgrounds--dark);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 64px;
  text-decoration: none;
  transition: opacity 0.2s;
  display: flex;
}

.account-nav-selection:hover {
  background-color: var(--backgrounds--background-primary-light);
}

.account-nav {
  grid-column-gap: var(--spacing-20x);
  grid-row-gap: var(--spacing-20x);
  flex-flow: column;
  display: flex;
}

.horizontal-divider {
  margin-top: var(--spacing-4x);
  margin-bottom: var(--spacing-4x);
  background-color: var(--surfaces--card-surface-light-2);
  width: 100%;
  height: 1px;
}

.account-right-side-container {
  grid-column-gap: var(--spacing-24x);
  grid-row-gap: var(--spacing-24x);
  flex-flow: column;
  display: flex;
}

.account-payment-card {
  padding-top: var(--spacing-6x);
  padding-bottom: var(--spacing-6x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-bottom: 1px solid var(--form-border-light);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.account-payment-reason-container {
  flex-flow: column;
  flex: 1;
  display: flex;
}

.account-payment-details-container {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.spotlight-stack {
  grid-column-gap: var(--spacing-16x);
  grid-row-gap: var(--spacing-16x);
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 1280px;
  display: flex;
}

.staff-image {
  border-radius: var(--border-styles--radius-full);
  width: 330px;
  height: 330px;
}

.biography-container {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: column;
  width: 100%;
  display: flex;
}

.staff-bio-container {
  grid-column-gap: var(--spacing-12x);
  grid-row-gap: var(--spacing-12x);
}

.staff-bio-stack {
  padding: 0;
}

.staff-socials-container {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.staff-social-icon {
  padding: var(--border-styles--radius-small) var(--spacing-2x);
  border-radius: var(--border-styles--radius-small);
  border: 1px solid #000;
}

.staff-social-icon-image {
  width: 14px;
  max-width: none;
  height: 14px;
}

.div-block-55 {
  flex: none;
  width: 330px;
  height: 330px;
}

.div-block-56 {
  grid-column-gap: var(--spacing-12x);
  grid-row-gap: var(--spacing-12x);
  flex-flow: column;
  flex: 0 auto;
  display: flex;
}

.div-block-59 {
  grid-column-gap: var(--radius-m);
  grid-row-gap: var(--radius-m);
  flex-flow: column;
  display: flex;
}

.div-block-60 {
  grid-column-gap: var(--spacing-4x);
  grid-row-gap: var(--spacing-4x);
  flex-flow: column;
  display: flex;
}

.legal-left-side {
  width: 50%;
  position: relative;
}

.legal-paragraph-block {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: column;
  display: flex;
}

.cell-13 {
  grid-column-gap: var(--spacing-20x);
  grid-row-gap: var(--spacing-20x);
}

.legal-stick-container {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  flex-flow: column;
  display: flex;
  position: relative;
}

.legal-container {
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  display: flex;
  position: relative;
}

.div-block-61 {
  position: relative;
}

.legal-right-side {
  grid-column-gap: var(--spacing-20x);
  grid-row-gap: var(--spacing-20x);
  flex-flow: column;
  display: flex;
}

.image-gallery-flex-horizontal {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  position: relative;
}

.image-gallery-flex-horizontal.offset {
  left: 66px;
}

.masterclass-gallery-image {
  border-radius: var(--radius-m);
  object-fit: cover;
  width: 221px;
  max-width: 221%;
  height: 140px;
}

.div-block-62 {
  flex: none;
  width: 40%;
}

.flex-block-2 {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: flex-end;
  overflow: visible;
}

.flex-block-3 {
  grid-column-gap: var(--spacing-36x);
  grid-row-gap: var(--spacing-36x);
  justify-content: flex-start;
  align-items: center;
}

.flex-block-4 {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
}

.flex-block-5 {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
}

.heading,
.heading-2,
.heading-3 {
  font-family: Exo, sans-serif;
}

.heading-4,
.heading-5,
.heading-6 {
  font-family: Lato, sans-serif;
}

.paragraph-12,
.block-quote {
  font-family: Exo, sans-serif;
}

.product-image {
  padding: var(--spacing-10x);
  border-radius: var(--radius-m);
  background-color: var(--text-colors--badge-text-dark-date);
  object-fit: contain;
  width: 100%;
  max-height: 50vh;
}

.div-block-63 {
  flex-flow: column;
  display: flex;
}

.div-block-64 {
  grid-column-gap: var(--spacing-16x);
  grid-row-gap: var(--spacing-16x);
  flex-flow: row;
  display: flex;
}

.flex-block-6 {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  justify-content: flex-start;
  align-items: center;
}

.cell-14 {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
}

.div-block-65 {
  padding: var(--spacing-4x);
  grid-column-gap: var(--spacing-3x);
  grid-row-gap: var(--spacing-3x);
  border-style: none none solid;
  border-width: 1px;
  border-color: var(--form-border-light);
  background-color: #0000;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.form-block-2 {
  margin-top: 0;
  margin-bottom: -10px;
}

.button-2 {
  color: var(--ash-black);
  background-color: #0000;
  padding: 0;
  font-size: 22px;
}

.text-field-2 {
  width: 30px;
  height: 30px;
}

.div-block-66 {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-block-67 {
  background-color: var(--text-colors--button-secondary-text-dark);
  width: 600px;
  position: absolute;
  inset: 0% 0% 0% auto;
}

.flex-block-7 {
  flex: 1;
  width: 100%;
}

.purchase-summary-container {
  top: var(--spacing-6x);
  padding: var(--spacing-6x) var(--spacing-5x);
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
  border-radius: var(--radius-m);
  background-color: var(--surfaces--card-surface-light);
  width: 330px;
  position: sticky;
}

.cart-container {
  width: 100%;
}

.cart-container.pad-top-m {
  grid-column-gap: var(--spacing-36x);
  grid-row-gap: var(--spacing-36x);
  position: relative;
}

.purchase-summary-total {
  padding-top: var(--spacing-3x);
  border-top: 1px solid var(--ash);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.purchase-summary-line {
  border-top: 1px none var(--ash);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.div-block-68 {
  grid-column-gap: var(--spacing-3x);
  grid-row-gap: var(--spacing-3x);
  flex-flow: column;
  width: 100%;
  display: flex;
}

.prodcut-summary-table-title-container {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.product-summary-line-item {
  padding-top: var(--spacing-6x);
  padding-bottom: var(--spacing-6x);
  border-bottom: 1px solid var(--form-text-secondary-light);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.button-dense-secondary-light {
  padding: 9.5px var(--spacing-6x) 10.5px 24px;
  border: 0.5px solid var(--button-colors--button-secondary-border-light);
  border-radius: var(--border-styles--radius-full);
  background-color: var(--button-colors--button-primary-surface-dark);
  color: var(--text-colors--button-primary-text-dark);
  text-align: center;
  font-family: Exo, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
}

.button-dense-secondary-light:focus {
  border-width: 2px;
}

.button-dense-secondary-light.margin-top-l {
  margin-top: var(--spacing-16x);
}

.div-block-69 {
  height: auto;
}

.purchase-summary-shipping-info {
  grid-column-gap: var(--spacing-2x);
  grid-row-gap: var(--spacing-2x);
  flex-flow: column;
  width: 100%;
  display: flex;
}

.flex-block-8 {
  grid-column-gap: var(--spacing-24x);
  grid-row-gap: var(--spacing-24x);
  flex: 1;
}

.form-block-3 {
  width: 100%;
}

.horizontal-field-group {
  grid-column-gap: var(--spacing-6x);
  grid-row-gap: var(--spacing-6x);
}

.product-summary-line-item-quatity {
  grid-column-gap: var(--spacing-3x);
  grid-row-gap: var(--spacing-3x);
  border: 1px none var(--form-border-light);
  background-color: #0000;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.checkbox-field {
  padding-top: var(--spacing-6x);
  padding-bottom: var(--spacing-6x);
}

.image-15 {
  aspect-ratio: 1.6;
  object-fit: contain;
}

@media screen and (max-width: 991px) {
  .event-card {
    width: 310px;
    max-width: 310px;
    overflow: hidden;
  }

  .image-3 {
    width: 268px;
    height: 268px;
  }

  .paragraph-section-paragraph-copy {
    padding-right: 0%;
  }

  .navbar-logo-container {
    flex: 0 auto;
    width: 200px;
    height: 18px;
    padding-top: 0;
  }

  .nav-bar {
    padding-top: var(--spacing-5x);
    padding-bottom: var(--spacing-5x);
    justify-content: flex-start;
    align-items: center;
    height: 56px;
    display: flex;
  }

  .hero-section {
    height: 700px;
    min-height: auto;
  }

  .div-block-9 {
    flex-flow: wrap;
    flex: none;
    justify-content: flex-end;
    align-items: center;
    width: auto;
  }

  .container {
    overflow: hidden;
  }

  .container.vertical-nav {
    padding-left: 10px;
    padding-right: 10px;
  }

  .container.gap-l {
    justify-content: space-between;
    align-items: flex-start;
    width: 650px;
  }

  .container.spotlight-stack {
    flex-flow: column;
  }

  .hero-heading {
    font-family: Exo, sans-serif;
  }

  .hero-heading.light {
    color: var(--text-colors--text-tertiary);
  }

  .hero-subheading {
    width: 100%;
  }

  .hero-subheading.hide-mo {
    display: none;
  }

  .event-card-section {
    padding: var(--spacing-16x) var(--spacing-2x);
    position: static;
  }

  .slider-button-left {
    top: 50%;
    left: 2%;
  }

  .slider-button-right {
    top: 50%;
    right: 5%;
  }

  .div-block-10 {
    justify-content: flex-start;
    align-items: flex-start;
    width: 60%;
  }

  .durkin-section {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    flex-flow: column;
  }

  .durkin-section-div,
  .durkin-section-div-2 {
    width: 100%;
  }

  .product-promo-card {
    grid-column-gap: var(--spacing-6x);
    grid-row-gap: var(--spacing-6x);
    flex: 1;
    width: 301px;
    min-width: 301px;
    max-width: 301px;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  .image-5 {
    width: 268px;
    height: 268px;
  }

  .footer {
    padding: 72px;
  }

  .background-video {
    padding-right: var(--spacing-4x);
    padding-left: var(--spacing-4x);
  }

  .masterclass-title {
    text-align: center;
  }

  .masterclass-hero-paragraph {
    width: 100%;
  }

  .masterclass-what-you-will-learn-section {
    padding: 40px;
    display: none;
  }

  .light-background-section {
    padding: 40px 10px;
  }

  .vertical.gap-l {
    grid-column-gap: var(--spacing-28x);
    grid-row-gap: var(--spacing-28x);
  }

  .quick-stack-9 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .white-background-section {
    padding: 40px 10px;
  }

  .div-block-25 {
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    flex-flow: wrap;
  }

  .staff-card-social-icon {
    border: 1px solid var(--video-overlay);
    background-color: #0000;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
  }

  .staff-card {
    flex: 0 auto;
    width: 48%;
    max-width: 100%;
    min-height: auto;
    margin: 0;
  }

  .image-9 {
    width: 15px;
    max-width: none;
    height: 15px;
  }

  .dark-background-section {
    padding-left: 20px;
    padding-right: 20px;
  }

  .dark-background-section.upcoming-events {
    padding-bottom: var(--spacing-28x);
  }

  .pricing-card-container {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 0 0;
    display: flex;
  }

  .pricing-card {
    flex: 1;
    width: auto;
  }

  .badge {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .pricing-card-top {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
    display: flex;
  }

  .quick-stack-10 {
    padding: 0;
  }

  .checkout-field.light {
    color: var(--text-tertiary-light-2);
  }

  .image-10 {
    flex: none;
  }

  .div-block-33 {
    flex: 0 auto;
  }

  .light-nav-bar {
    position: relative;
  }

  .navbar-container {
    flex-flow: column;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-container.hide-mobile {
    display: none;
  }

  .button-dense-primary-dark {
    text-align: center;
    line-height: 20px;
  }

  .button-dense-primary-light {
    font-family: Exo, sans-serif;
  }

  .display-xxl {
    font-size: 96px;
    line-height: 94px;
  }

  .header {
    font-size: 72px;
    line-height: 72px;
  }

  .header-xxl {
    font-size: 40px;
    line-height: 42px;
  }

  .header-xl {
    font-size: 36px;
    line-height: 38px;
  }

  .header-xl.light {
    font-size: 36px;
  }

  .header-l {
    font-size: 32px;
    line-height: 34px;
  }

  .header-m {
    font-size: 26px;
    line-height: 28px;
  }

  .header-s {
    font-size: 22px;
    line-height: 30px;
  }

  .header-xs {
    font-size: 18px;
    line-height: 20px;
  }

  .header-xs.center {
    padding-top: var(--spacing-3x);
    padding-bottom: var(--spacing-3x);
  }

  .body-l {
    font-size: 20px;
    line-height: 22px;
  }

  .button.m.dark {
    font-family: Exo, sans-serif;
    font-weight: 400;
  }

  .display-xl {
    font-size: 96px;
    line-height: 94px;
  }

  .blue-background-section {
    padding: 40px 10px;
  }

  .masterclass-nav-logo {
    flex: none;
  }

  .masterclass-floating-boxes {
    padding: 0;
  }

  .full-width-stack {
    flex: 1;
  }

  .brown-background-section {
    padding-left: 60px;
    padding-right: 60px;
  }

  .lightgrey-background-section {
    padding: 40px 10px;
  }

  .hide-desktop {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 32px;
    display: flex;
  }

  .hamburger-container {
    height: 100%;
  }

  .div-block-44 {
    width: 100%;
  }

  .cell-9,
  .cell-10,
  .cell-11 {
    justify-content: flex-start;
    align-items: center;
  }

  .div-block-45,
  .div-block-46 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-flow: column;
    display: flex;
  }

  .green-background-section,
  .plum-background-section {
    padding: 40px 10px;
  }

  .team-card {
    flex: 0 auto;
    width: 301px;
    max-width: 100%;
    min-height: auto;
    margin: 0;
  }

  .parner-card-container {
    overflow: clip;
  }

  .partner-card {
    min-width: 330px;
  }

  .partner-product {
    width: 300px;
    min-width: 300px;
  }

  .staff-card-container {
    flex-flow: wrap;
    justify-content: center;
    align-items: baseline;
  }

  .product-container {
    grid-column-gap: var(--spacing-2x);
    grid-row-gap: var(--spacing-2x);
    justify-content: space-around;
  }

  .product-card {
    width: 300px;
    min-width: 300px;
  }

  .signup-right-side {
    display: none;
  }

  .signup-nav-bar {
    padding-right: var(--spacing-16x);
    padding-left: var(--spacing-16x);
  }

  .div-block-53 {
    left: 79px;
    right: auto;
  }

  .slider-container {
    width: 770px;
    overflow: auto;
  }

  .text-block-8 {
    font-size: 12px;
    line-height: 16px;
  }

  .account-stack {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  .account-nav {
    display: none;
  }

  .spotlight-stack {
    flex-flow: column;
    padding: 0;
  }

  .staff-bio-stack {
    padding-left: 0;
    padding-right: 0;
  }

  .div-block-56 {
    grid-column-gap: var(--spacing-12x);
    grid-row-gap: var(--spacing-12x);
    flex-flow: column;
    display: flex;
  }

  .mobile-nav-container {
    flex-flow: column;
    justify-content: flex-start;
    width: 430px;
    height: 100vh;
    display: flex;
  }

  .mobile-nav-container.hide-desktop {
    padding-bottom: 100px;
    transition: all 0.5s;
    position: fixed;
  }

  .mobile-nav-link {
    padding: var(--spacing-4x);
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    display: flex;
  }

  .small-icon {
    width: 18px;
    height: 18px;
  }

  .div-block-57 {
    padding-top: var(--spacing-16x);
    padding-right: var(--spacing-3x);
    padding-left: var(--spacing-3x);
    grid-column-gap: var(--spacing-12x);
    grid-row-gap: var(--spacing-12x);
    flex-flow: column;
    flex: 1;
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .div-block-58 {
    grid-column-gap: var(--spacing-12x);
    grid-row-gap: var(--spacing-12x);
    flex-flow: column;
    display: flex;
  }

  .legal-left-side {
    display: none;
  }

  .legal-paragraph-block {
    width: 100%;
  }

  .cart-container.pad-top-m {
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }

  .button-dense-secondary-light {
    font-family: Exo, sans-serif;
  }

  .flex-block-8 {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .div-block-9 {
    justify-content: center;
    align-items: center;
  }

  .container.gap-l {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .durkin-section-div {
    justify-content: flex-start;
    align-items: center;
  }

  .image-4 {
    width: 300px;
    height: 300px;
  }

  .product-promo-card {
    min-width: 330px;
  }

  .breadcrumb-nav {
    display: none;
  }

  .masterclass-title {
    padding-top: 100px;
  }

  .masterclass-hero-paragraph {
    width: 41ch;
  }

  .div-block-21 {
    flex-flow: column;
  }

  .div-block-25 {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-flow: wrap;
  }

  .staff-card {
    flex: 0 auto;
    width: 400px;
    max-width: 400px;
  }

  .masterclass-location-details-container {
    flex-flow: column;
  }

  .div-block-26,
  .div-block-27 {
    width: 100%;
  }

  .code-embed-2 {
    width: 100%;
    display: none;
  }

  .dark-background-section.masterclass-floating-boxes {
    padding: 40px 20px;
  }

  .dark-background-section.image {
    height: 40vh;
  }

  .button-dense-primary-light {
    text-align: center;
    line-height: 24px;
  }

  .date-badge-light.general-1 {
    flex: 0 auto;
  }

  .header-xl.light.pad-top-xs {
    padding-top: var(--spacing-4x);
    line-height: 40px;
  }

  .header-xl.pad-top-l {
    line-height: 40px;
  }

  .header-l.thin.light,
  .header-l.thin.pad-top-m {
    line-height: 42px;
  }

  .header-s {
    flex: 1;
  }

  .body-m.light.short {
    width: 100%;
  }

  .footer-link-stack {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .cell-3 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
  }

  .horizontal {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    flex-flow: column;
  }

  .masterclass-floating-boxes {
    position: relative;
    inset: 0;
  }

  .div-block-45 {
    width: 100%;
  }

  .large-photo-left {
    height: 350px;
  }

  .large-photo-right {
    height: 163px;
  }

  .team-card {
    flex: 0 auto;
    width: 278px;
    min-width: 278px;
    max-width: none;
  }

  .card-type-4.margin-top-l {
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .div-block-48 {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .parner-card-container {
    overflow: clip;
  }

  .partner-card {
    width: 300px;
    min-width: 300px;
  }

  .featured-event-image {
    height: 350px;
  }

  .featured-event-details-bar {
    flex-flow: column;
  }

  .featured-event-title-bar {
    justify-content: flex-start;
    width: 100%;
  }

  .featured-event-title,
  .class-row {
    width: 100%;
  }

  .staff-card-container {
    grid-column-gap: var(--spacing-6x);
    grid-row-gap: var(--spacing-6x);
    flex-flow: row;
    justify-content: flex-start;
    align-items: stretch;
  }

  .staff-card-container.slider-container {
    padding-right: 281px;
  }

  .shop-filter-container.pad-top-m {
    display: none;
  }

  .product-container {
    justify-content: center;
  }

  .signup-nav-bar {
    padding-right: var(--spacing-5x);
    padding-left: var(--spacing-5x);
  }

  .div-block-53 {
    width: 100%;
    margin-right: 0;
    left: auto;
  }

  .slider-container,
  .mobile-nav-container,
  .div-block-57 {
    width: 100%;
  }

  .button-dense-secondary-light {
    text-align: center;
    line-height: 24px;
  }
}

@media screen and (max-width: 479px) {
  .event-card {
    width: 278px;
    min-width: 278px;
    max-width: 278px;
  }

  .image-3 {
    width: 230px;
    height: 230px;
  }

  .paragraph-section-paragraph-copy {
    width: 100%;
    padding-right: 0%;
  }

  .navbar-logo-container {
    width: 206px;
    overflow: visible;
  }

  .nav-bar {
    padding-right: var(--spacing-4x);
    padding-left: var(--spacing-4x);
  }

  .div-block-9 {
    display: none;
  }

  .container.gap-l {
    padding-left: 0;
    padding-right: 0;
  }

  .container.gap-l.overflow {
    overflow: clip;
  }

  .container.left-justify {
    grid-column-gap: var(--spacing-5x);
    grid-row-gap: var(--spacing-5x);
  }

  .container.horizontal {
    grid-column-gap: var(--spacing-4x);
    grid-row-gap: var(--spacing-4x);
    flex-flow: row;
  }

  .hero-heading {
    font-size: 40px;
    line-height: 46px;
  }

  .div-block-10 {
    grid-column-gap: var(--spacing-4x);
    grid-row-gap: var(--spacing-4x);
    width: 100%;
  }

  .durkin-section {
    grid-column-gap: var(--spacing-4x);
    grid-row-gap: var(--spacing-4x);
  }

  .image-4 {
    object-fit: cover;
    max-width: none;
  }

  .full-video {
    border-radius: var(--border-styles--radius-small);
  }

  .product-promo-card {
    padding-top: var(--spacing-4x);
    padding-bottom: var(--spacing-4x);
    grid-column-gap: var(--spacing-2x);
    grid-row-gap: var(--spacing-2x);
    background-color: var(--surfaces--card-surface-light-2);
    flex: none;
    width: 278px;
    min-width: 278px;
    max-width: 2781px;
    height: auto;
    min-height: auto;
    max-height: none;
  }

  .image-5 {
    width: 200px;
    max-width: none;
    height: 200px;
  }

  .background-video {
    padding-left: 10px;
    padding-right: 10px;
  }

  .masterclass-title,
  .masterclass-hero-paragraph {
    width: 100%;
  }

  .badge-dark {
    flex: 1;
    min-width: 60px;
    height: auto;
  }

  .light-background-section {
    padding-top: var(--spacing-28x);
    padding-bottom: var(--spacing-28x);
  }

  .vertical.gap-l {
    grid-column-gap: var(--spacing-10x);
    grid-row-gap: var(--spacing-10x);
  }

  .agenda-line-item {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .div-block-25 {
    flex-flow: row;
  }

  .staff-card {
    width: 82%;
    min-width: 82%;
    margin: 0;
  }

  .masterclass-location-details-container {
    flex-flow: column-reverse;
    box-shadow: 0 2px 5px #0003;
  }

  .code-embed-2 {
    display: none;
  }

  .dark-background-section {
    padding: var(--spacing-28x) 10px;
  }

  .dark-background-section.image {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .dark-background-section.upcoming-events {
    padding-top: var(--spacing-5x);
  }

  .pricing-card {
    max-height: 600px;
  }

  .pricing-card-price {
    font-size: 70px;
  }

  .pricing-card-price.dark {
    color: var(--text-tertiary-light-2);
  }

  .badge {
    padding: var(--spacing-2x);
    grid-column-gap: var(--spacing-2x);
    grid-row-gap: var(--spacing-2x);
    margin-top: 0;
    margin-bottom: 0;
  }

  .button-secondary-dark {
    text-align: center;
  }

  .light-nav-bar {
    padding-left: 10px;
    padding-right: 10px;
  }

  .medium-button-secondary-light.margin-top-m {
    margin-top: var(--spacing-2x);
  }

  .navbar-container {
    width: 100%;
  }

  .button-primary-dark,
  .button-primary-light {
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }

  .button-dense-primary-dark {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }

  .button-dense-primary-dark.hide-mobile {
    display: none;
  }

  .button-dense-primary-light {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }

  .header-xxl {
    width: 100%;
  }

  .header-xxl.light {
    line-height: 48px;
  }

  .header-xxl.light.pad-top-xs {
    padding-top: var(--spacing-4x);
  }

  .header-xxl.pad-top-m {
    line-height: 44px;
  }

  .header-xl,
  .header-xl.light {
    line-height: 43px;
  }

  .header-xl.light.pad-top-xs {
    padding-top: var(--spacing-4x);
  }

  .header-xl.pad-top-l {
    line-height: 40px;
  }

  .header-xl.pad-top-m {
    padding-top: var(--spacing-16x);
  }

  .header-l.thin.light {
    color: var(--text-tertiary-light-2);
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
  }

  .header-l.thin.pad-top-m {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
  }

  .header-m {
    flex: 0 auto;
    line-height: 26px;
  }

  .header-m.thin {
    line-height: 29px;
  }

  .header-s {
    flex: 0 auto;
    font-weight: 400;
  }

  .header-s.regular.pad-top-xl {
    padding-top: var(--spacing-20x);
  }

  .header-xs {
    font-weight: 400;
    line-height: 24px;
  }

  .header-xs.center {
    padding-top: var(--spacing-3x);
    padding-bottom: var(--spacing-3x);
  }

  .body-m {
    line-height: 26px;
  }

  .body-s.light.short {
    width: 85%;
  }

  .button {
    border-radius: var(--border-styles--radius-full);
  }

  .footer-link-stack {
    grid-column-gap: 64px;
    grid-row-gap: 64px;
  }

  .cell-3 {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    padding: 60px 10px;
  }

  .cell-4,
  .cell-5,
  .cell-6 {
    justify-content: flex-start;
    align-items: center;
  }

  .footer-privacy-bar {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    flex-flow: column;
  }

  .background-video-masterclass {
    height: 410px;
  }

  .display-xl {
    font-size: 60px;
    line-height: 60px;
  }

  .div-block-38 {
    flex: none;
  }

  .horizontal {
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }

  .blue-background-section {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .div-block-40 {
    flex-flow: column;
  }

  .image-11 {
    width: 100%;
  }

  .button-group {
    flex-flow: row;
  }

  .button-group.no-pad {
    grid-column-gap: var(--spacing-4x);
    grid-row-gap: var(--spacing-4x);
    flex-flow: row;
  }

  .button-group.center.vertical {
    flex-flow: column;
  }

  .full-width-stack {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .full-width-stack.pad-top-xl {
    padding-top: 0;
    padding-bottom: 0;
  }

  .full-width-stack.pad-top-l {
    grid-column-gap: var(--spacing-10x);
    grid-row-gap: var(--spacing-10x);
  }

  .glp-info-container {
    justify-content: center;
    align-items: center;
  }

  .brown-background-section {
    padding: var(--spacing-28x) 24px;
  }

  .lightgrey-background-section {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .link-block.icon-button {
    flex: none;
  }

  .button-m.light {
    color: var(--light);
  }

  .plum-background-section {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .team-card {
    margin: 0;
  }

  .card-type-4.margin-top-l {
    padding-top: var(--spacing-6x);
    padding-left: var(--spacing-4x);
  }

  .partner-card {
    width: 225px;
    min-width: 225px;
  }

  .partner-product {
    min-width: 250px;
    height: 400px;
  }

  .partner-product-image {
    height: 230px;
  }

  .form-2-fields {
    flex-flow: column;
  }

  .featured-event-image {
    height: 175px;
  }

  .div-block-50 {
    grid-column-gap: var(--spacing-2x);
    grid-row-gap: var(--spacing-2x);
    flex-flow: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .event-filter-bar {
    flex-flow: wrap;
    overflow: clip;
  }

  .event-card-type-2 {
    padding: var(--spacing-4x);
  }

  .class-list {
    padding-top: var(--spacing-12x);
  }

  .class-list.pad-top-m {
    grid-column-gap: var(--spacing-4x);
    grid-row-gap: var(--spacing-4x);
  }

  .event-card-type-3 {
    padding: var(--spacing-4x);
  }

  .staff-card-container {
    flex-flow: row;
  }

  .staff-card-container.slider-container.pad-top-m {
    width: 100%;
    overflow: scroll;
  }

  .shop-filter-container.pad-top-m {
    flex-flow: wrap;
    display: none;
  }

  .shop-chosen-filters-container {
    display: none;
  }

  .product-container {
    grid-column-gap: var(--spacing-5x);
    grid-row-gap: var(--spacing-5x);
  }

  .text-block-5,
  .text-block-6,
  .text-block-7 {
    text-align: center;
  }

  .product-card {
    min-width: 250px;
    height: 400px;
  }

  .field-group {
    width: 100%;
  }

  .signup-form {
    width: 250px;
  }

  .div-block-53 {
    padding: var(--spacing-6x) var(--spacing-4x);
    width: 100%;
    position: sticky;
    inset: 77% 0% auto;
  }

  .cookies-close-button {
    top: 12px;
    right: 12px;
  }

  .quick-stack-11 {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-left: 0;
    padding-right: 0;
  }

  .button-primary-blue {
    background-color: var(--backgrounds--dark-blue);
    text-align: center;
    font-size: 16px;
    line-height: 16px;
  }

  .slider-container {
    grid-column-gap: var(--spacing-5x);
    grid-row-gap: var(--spacing-5x);
    width: 100%;
    overflow: scroll;
  }

  .account-right-side {
    width: 100%;
  }

  .staff-image {
    width: 230px;
    height: 230px;
  }

  .biography-container,
  .staff-bio-stack {
    grid-column-gap: var(--spacing-10x);
    grid-row-gap: var(--spacing-10x);
  }

  .div-block-55 {
    width: 230px;
    height: 230px;
  }

  .div-block-56 {
    width: 100%;
  }

  .mobile-nav-container {
    width: 100vw;
    height: 100vh;
  }

  .mobile-nav-header {
    width: 100%;
  }

  .mobile-nav-link-container {
    padding-right: var(--spacing-3x);
  }

  .mobile-nav-link {
    padding-right: var(--spacing-3x);
    padding-left: var(--spacing-3x);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    text-decoration: none;
    display: flex;
  }

  .small-icon {
    width: 18px;
    height: 18px;
  }

  .div-block-57 {
    padding-right: var(--spacing-3x);
    padding-left: var(--spacing-3x);
    grid-column-gap: var(--spacing-12x);
    grid-row-gap: var(--spacing-12x);
    flex-flow: column;
    display: flex;
  }

  .product-image {
    max-height: 25vh;
  }

  .cart-container.pad-top-m {
    grid-column-gap: var(--spacing-5x);
    grid-row-gap: var(--spacing-5x);
  }

  .product-summary-line-item {
    grid-column-gap: var(--spacing-3x);
    grid-row-gap: var(--spacing-3x);
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
  }

  .button-dense-secondary-light {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }

  .horizontal-field-group {
    flex-flow: column;
  }
}

#w-node-d11065e9-8e2b-9f66-4819-4f26221f1c45-05e45dd5 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}

#w-node-d81c9dab-203c-d8fc-2a85-5c2d13eda7b0-05e45dd5 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_6906d351-9474-9686-1e41-0d19c3f52231-05e45dd5 {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

#w-node-_55ea0730-9a19-0bbe-728d-d1cf383767b7-05e45dd5,
#w-node-ed344a2a-d137-dee8-e790-6fe732b339b0-05e45dd5,
#w-node-_49c61dff-c8c7-41d2-4770-b4dfc18fc3f5-05e45dd5 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316a-6a1c3167 {
  grid-template-rows: auto;
  grid-template-columns: 2.5fr 1fr;
}

#w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316c-6a1c3167 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-f1c0f900-2e27-2b42-3467-77bb84e8d770-84e8d76c {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-_8ff770a1-9997-4c80-5c92-623e14884223-14884223 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-_54c55a5b-748d-31dd-84b6-de8a925bd04f-34be301c,
#w-node-_83064454-a174-f18d-cf8a-2b9547c3d34c-34be301c,
#w-node-_863262b1-7738-3b18-2e2f-ee97429f08a2-34be301c,
#w-node-_1179d6a9-80fb-5956-929a-85b0e05086b9-34be301c,
#w-node-_861a9449-cf4d-4fc3-07c0-e7dff9ce6d3e-34be301c,
#w-node-_6ab70057-ea47-590f-e38f-7a07f4462fd0-34be301c {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_63ab8c39-bdfa-ee00-93cc-4abbd4a82278-8d799a3f {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-c2786c19-6ee8-0e8b-cd96-62a0aba960c7-8d799a3f,
#w-node-_01cbe693-7887-6686-a78e-267ceb8039c0-8d799a3f {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-e67cb3d1-e283-c884-a313-fe13074e63c9-8d799a3f {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a89-625ce5d6 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_54bee268-f6a5-3c74-d593-f8f42c6383be-6e8a08cb,
#w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b319-6e8a08cb {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-b0e68f4f-f799-fab6-ff2d-e3e6a73061c8-6e8a08cb,
#w-node-b5b24049-a189-ff5c-f80d-81837e9e6e84-6e8a08cb {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-_93b5f37f-02d4-6d75-2e62-f9e86e6cd4a8-6df1ccd1 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_0c7ab839-6dfe-06e3-5323-e50b8d72df5f-6df1ccd1,
#w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d27f5-f35d27f5,
#w-node-_38632b1b-bb33-f969-1fd8-67b44ffe3394-1d55edf2,
#w-node-_4c7db1e8-6b16-8ee4-9465-67409799a42e-1d55edf2 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-bb207b8c-0cf1-5f0f-be22-a0836f0eee08-e2cbdfff,
#w-node-_879f8681-b374-63f6-4710-0f3ea1e78f7c-e2cbdfff {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_8fe4ec08-ff30-22b0-df13-8f706664ce01-d9271105 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-a534e4f9-b38b-c08b-2756-cbdda0858cd3-f5256702 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-_70a8bbcc-b4b5-b0ef-8b37-90d876486b0b-f5256702 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-bc4e0c1b-c760-b800-b765-3a76cd180945-f5256702,
#w-node-baf977d2-a00a-51e6-8143-d91326088cae-f5256702 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d298-eba3d289 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fa-3ba2f31c {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-_16e638ac-8f60-6cc4-07e2-8d2c832ac2a2-a3dbe62f {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
}

#w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fa-462cfb12,
#w-node-aa643880-0aa8-704a-aed3-1b7c21207061-b3efc38c {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

#w-node-cd22e3e1-eff0-7189-bbce-71074a68b304-b3efc38c {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

#w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-ff461701,
#w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-e000439a,
#w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-314c76f3 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 0.5fr;
}

#w-node-_54e7edc1-5170-ad65-a5dc-43245f933613-cb120944 {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 991px) {
  #w-node-d11065e9-8e2b-9f66-4819-4f26221f1c45-05e45dd5 {
    display: none;
  }

  #w-node-_55ea0730-9a19-0bbe-728d-d1cf383767b7-05e45dd5,
  #w-node-ed344a2a-d137-dee8-e790-6fe732b339b0-05e45dd5 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-f1c0f900-2e27-2b42-3467-77bb84e8d770-84e8d76c {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-_8ff770a1-9997-4c80-5c92-623e14884223-14884223 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-c2786c19-6ee8-0e8b-cd96-62a0aba960c7-8d799a3f {
    grid-template-rows: 3fr;
    grid-template-columns: 1fr;
  }

  #w-node-_01cbe693-7887-6686-a78e-267ceb8039c0-8d799a3f {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-e67cb3d1-e283-c884-a313-fe13074e63c9-8d799a3f {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-_00638fb3-0e66-b414-23a0-ee9b526b3a89-625ce5d6,
  #w-node-_54bee268-f6a5-3c74-d593-f8f42c6383be-6e8a08cb,
  #w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b319-6e8a08cb {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-b0e68f4f-f799-fab6-ff2d-e3e6a73061c8-6e8a08cb,
  #w-node-b5b24049-a189-ff5c-f80d-81837e9e6e84-6e8a08cb,
  #w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1,
  #w-node-_0c7ab839-6dfe-06e3-5323-e50b8d72df5f-6df1ccd1,
  #w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d27f5-f35d27f5,
  #w-node-_38632b1b-bb33-f969-1fd8-67b44ffe3394-1d55edf2,
  #w-node-_4c7db1e8-6b16-8ee4-9465-67409799a42e-1d55edf2,
  #w-node-_8fe4ec08-ff30-22b0-df13-8f706664ce01-d9271105 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-a534e4f9-b38b-c08b-2756-cbdda0858cd3-f5256702 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-_70a8bbcc-b4b5-b0ef-8b37-90d876486b0b-f5256702 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-bc4e0c1b-c760-b800-b765-3a76cd180945-f5256702 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-baf977d2-a00a-51e6-8143-d91326088cae-f5256702,
  #w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d298-eba3d289 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fa-3ba2f31c {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-_16e638ac-8f60-6cc4-07e2-8d2c832ac2a2-a3dbe62f {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-_9be168e8-e24e-8697-2f5a-398ee81e90fa-462cfb12,
  #w-node-aa643880-0aa8-704a-aed3-1b7c21207061-b3efc38c {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-cd22e3e1-eff0-7189-bbce-71074a68b304-b3efc38c {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }

  #w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-ff461701,
  #w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-e000439a,
  #w-node-c6d14739-3c42-80c8-0137-3dc5425f747f-314c76f3,
  #w-node-_54e7edc1-5170-ad65-a5dc-43245f933613-cb120944 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 767px) {
  #w-node-_49c61dff-c8c7-41d2-4770-b4dfc18fc3f5-05e45dd5 {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }

  #w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316a-6a1c3167 {
    grid-template-rows: auto;
    grid-template-columns: 2.5fr;
  }

  #w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316c-6a1c3167 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  #w-node-_63ab8c39-bdfa-ee00-93cc-4abbd4a82278-8d799a3f,
  #w-node-_93b5f37f-02d4-6d75-2e62-f9e86e6cd4a8-6df1ccd1 {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 479px) {
  #w-node-d81c9dab-203c-d8fc-2a85-5c2d13eda7b0-05e45dd5,
  #w-node-c55ab717-e0c6-e697-37ab-8fb76a1c316c-6a1c3167,
  #w-node-f1c0f900-2e27-2b42-3467-77bb84e8d770-84e8d76c,
  #w-node-_63ab8c39-bdfa-ee00-93cc-4abbd4a82278-8d799a3f,
  #w-node-e67cb3d1-e283-c884-a313-fe13074e63c9-8d799a3f,
  #w-node-_54bee268-f6a5-3c74-d593-f8f42c6383be-6e8a08cb,
  #w-node-_9fdf70d8-3fd7-7c24-aef1-21ea9983b319-6e8a08cb,
  #w-node-b0e68f4f-f799-fab6-ff2d-e3e6a73061c8-6e8a08cb,
  #w-node-b5b24049-a189-ff5c-f80d-81837e9e6e84-6e8a08cb,
  #w-node-baf977d2-a00a-51e6-8143-d91326088cae-6df1ccd1,
  #w-node-_93b5f37f-02d4-6d75-2e62-f9e86e6cd4a8-6df1ccd1,
  #w-node-_0c7ab839-6dfe-06e3-5323-e50b8d72df5f-6df1ccd1,
  #w-node-_29b8e1ec-e0e5-75cb-6319-a2f9f35d27f5-f35d27f5,
  #w-node-_38632b1b-bb33-f969-1fd8-67b44ffe3394-1d55edf2,
  #w-node-_4c7db1e8-6b16-8ee4-9465-67409799a42e-1d55edf2,
  #w-node-bb207b8c-0cf1-5f0f-be22-a0836f0eee08-e2cbdfff,
  #w-node-_879f8681-b374-63f6-4710-0f3ea1e78f7c-e2cbdfff,
  #w-node-_8fe4ec08-ff30-22b0-df13-8f706664ce01-d9271105,
  #w-node-_70a8bbcc-b4b5-b0ef-8b37-90d876486b0b-f5256702,
  #w-node-baf977d2-a00a-51e6-8143-d91326088cae-f5256702,
  #w-node-_5b2bdfd3-45f6-4ecf-b4a3-69f7eba3d298-eba3d289,
  #w-node-_16e638ac-8f60-6cc4-07e2-8d2c832ac2a2-a3dbe62f,
  #w-node-cd22e3e1-eff0-7189-bbce-71074a68b304-b3efc38c {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
}

.calendar,
.react-calendar {
  width: 100% !important;
  height: 100% !important;
}

.calendarTile,
.calendarTile > button {
  color: black;
  height: 150px !important;
  border-color: black !important;
  border-width: thin !important;
  border-style: solid !important;
}

.calendarTile:hover {
  background-color: lightgrey !important;
}

.calendarTileSelected {
  background-color: black !important;
  color: white;
  height: 150px !important;
  border-color: black !important;
  border-width: 2 !important;
  border-style: solid !important;
}

.react-calendar__month-view__days > button {
  height: 100%;
}

.dotContainer {
  flex-direction: row;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.privateDot {
  width: 20px;
  height: 20px;
  background-color: #fe003c;
  border-radius: 50%;
}

.publicDot {
  width: 20px;
  height: 20px;
  background-color: #fd0d95;
  border-radius: 50%;
}

.day-details-container {
  grid-row-gap: 40px;
  background-color: var(--light-grey);
  flex-direction: column;
  flex: 0 auto;
  width: 30%;
  min-height: 1000px;
  padding: 40px;
  display: flex;
}

.whole-calendar-container {
  width: 100%;
  max-width: 1920px;
  min-height: 400px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.calendar-container {
  width: 70%;
  flex: 0 auto;
}

@media only screen and (max-width: 900px) {
  .calendar,
  .react-calendar {
    width: 100% !important;
    height: 100% !important;
  }

  .calendarTile,
  .calendarTile > button {
    color: black;
    height: 75px !important;
    border-color: black !important;
    border-width: thin !important;
    border-style: solid !important;
  }

  .calendarTile:hover {
    background-color: lightgrey !important;
  }

  .calendarTileSelected {
    background-color: black !important;
    color: white;
    height: 75px !important;
    border-color: black !important;
    border-width: 2 !important;
    border-style: solid !important;
  }

  .react-calendar__month-view__days > button {
    height: 100%;
  }

  .dotContainer {
    flex-direction: column !important;
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 10px !important;
  }

  .privateDot {
    width: 10px;
    height: 10px;
    background-color: #fe003c;
    border-radius: 50%;
  }

  .publicDot {
    width: 10px;
    height: 10px;
    background-color: #fd0d95;
    border-radius: 50%;
  }
}
